import { SET_OPEN_POPUP_SIGN_UP, SET_PACKAGE_ID, SET_IS_LOGIN, SET_DARK_MODE } from '../actions/types'

const INITIAL_STATE = {
     isOpenPopupSignUp: false,
     packageId: '',
     isLogin: null,
     darkModeRedux: false,
}

export default (state = INITIAL_STATE, action) => {
     switch (action.type) {
          case SET_OPEN_POPUP_SIGN_UP:
               return { ...state, isOpenPopupSignUp: action.payload }
          case SET_PACKAGE_ID:
               return { ...state, packageId: action.payload }
          case SET_IS_LOGIN:
               return { ...state, isLogin: action.payload }
          case SET_DARK_MODE:
               return { ...state, darkModeRedux: action.payload }
          default:
               return state
     }
}
