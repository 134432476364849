/**
 * Main store function
 */
import { createStore } from "redux";
import rootReducer from "./src/reducers";

export function configureStore(initialState = {}) {
    const store = createStore(
        rootReducer,
        initialState,
    );
    return store;
}